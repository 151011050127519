import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";
import { DragDropModule } from '@angular/cdk/drag-drop';

import { DataCommonBase } from './data-common';
import { DataInputComponent } from './data-input/data-input.component';
import { FormComponent } from './form/form.component';
import { InputEmailComponent } from './input-email/input-email.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { InputTextComponent } from './input-text/input-text.component';
import { InputTextAreaComponent } from './input-text-area/input-text-area.component';
import { InputPasswordComponent } from './input-password/input-password.component';
import { InputRadioSingleComponent } from './input-radio-single/input-radio-single.component';
import { InputRadioGroupComponent } from './input-radio-group/input-radio-group.component';
import { InputCheckboxGroupComponent } from './input-checkbox-group/input-checkbox-group.component';
import { InputCheckboxSingleComponent } from './input-checkbox-single/input-checkbox-single.component';
import { InputSearchComponent } from './input-search/input-search.component';
import { InputImageComponent } from './input-image/input-image.component';
import { InputFileComponent } from './input-file/input-file.component';
import { InputDateComponent } from './input-date/input-date.component';
import { InputTimeComponent } from './input-time/input-time.component';
import { InputUrlComponent } from './input-url/input-url.component';
import { InputTelComponent } from './input-tel/input-tel.component';
import { InputRangeComponent } from './input-range/input-range.component';
import { InputDatetimeComponent } from './input-datetime/input-datetime.component';
import { InputSubmitComponent } from './input-submit/input-submit.component';
import { InputResetComponent } from './input-reset/input-reset.component';
import { InputColorComponent } from './input-color/input-color.component';
import { LabelComponent } from './label/label.component';
import { SelectSingleComponent } from './select-single/select-single.component';
import { SelectMultipleComponent } from './select-multiple/select-multiple.component';


@NgModule({
  providers: [DataCommonBase],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    SharedModule,
  ],
  declarations: [
    InputEmailComponent,
    InputTextComponent,
    InputTextAreaComponent,
    InputNumberComponent,
    InputPasswordComponent,
    InputRadioSingleComponent,
    InputRadioGroupComponent,
    InputCheckboxGroupComponent,
    InputCheckboxSingleComponent,
    InputSearchComponent,
    InputImageComponent,
    InputFileComponent,
    InputDateComponent,
    InputTimeComponent,
    InputUrlComponent,
    InputTelComponent,
    InputRangeComponent,
    InputDatetimeComponent,
    InputSubmitComponent,
    InputResetComponent,
    InputColorComponent,
    SelectSingleComponent,
    SelectMultipleComponent,
    LabelComponent,
    FormComponent,
    DataInputComponent,
    // DataTextInputComponent,
  ],
  exports: [
    InputEmailComponent,
    InputTextComponent,
    InputTextAreaComponent,
    InputNumberComponent,
    InputPasswordComponent,
    InputRadioSingleComponent,
    InputRadioGroupComponent,
    InputCheckboxGroupComponent,
    InputCheckboxSingleComponent,
    InputSearchComponent,
    InputImageComponent,
    InputFileComponent,
    InputDateComponent,
    InputTimeComponent,
    InputUrlComponent,
    InputTelComponent,
    InputRangeComponent,
    InputDatetimeComponent,
    InputSubmitComponent,
    InputResetComponent,
    InputColorComponent,
    SelectSingleComponent,
    SelectMultipleComponent,
    LabelComponent,
    FormComponent,
    DataInputComponent,
    // DataTextInputComponent,
  ]
})
export class QuickHtmlModule { }
