import { model, input, Component } from "@angular/core";

@Component({
  template: '<div></div>'
})
export class DataCommonBase {
  // Reminder - draDrop has no internal select inputType
  // All selects are handled by parent switch stmt & indivdiual ng-templates
  enable_FieldDragDrop = input(false);

  fieldType = input.required<string>();
  fieldMin = input<string>();
  fieldMax = input<string>();
  fieldRequired = input(false);
  fieldReadonly = input(false);

  fieldModel = model.required();
  fieldName = input.required<string>();
  fieldLabel = input.required<string>();

  // This must be overridden in extending classes
  optionString: string;
}
