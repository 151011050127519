<div id="FIELD-{{ fieldName() }}" cdkDrag [cdkDragData]="ismField" class="w-full text-black flex items-center" [ngClass]="{ 'dragFieldItem mx-1 amkBlue-lte': enableDragDrop() }">
  @if (enableDragDrop()) {
    <div id="_field_drag-handle_" [ngClass]="{ fieldDragHandle: enableDragDrop }" cdkDragHandle>
      <ism-svg [name]="'dropHandle'"></ism-svg>
    </div>
  }
  <gray-label-wrapper [fieldLabel]="fieldLabel()" [fieldName]="fieldName()" class="w-full text-black flex items-center">
    @switch (fieldType()) {
      @case ("select") {
        @if (templateRef() == null) {
          <div>No select TemplateRef for {{ fieldName() }}!</div>
        }
        <!-- make sure all selects a parent ng-template -->
        @else {
          <ng-container *ngTemplateOutlet="templateRef()"></ng-container>
        }
      }
      @case ("number") {
        @if (fieldMin() && fieldMax()) {
          <input [attr.id]="fieldName()" [attr.name]="fieldName()" type="number" [attr.min]="fieldMin()" [attr.max]="fieldMax()" [(ngModel)]="fieldModel" [required]="fieldRequired()" [readonly]="fieldReadonly()" class="w-full h-full form-input rounded-r-md" />
        }
        <!-- number with no min & no max  -->
        @else {
          <input [attr.id]="fieldName()" [attr.name]="fieldName()" type="number" [(ngModel)]="fieldModel" [required]="fieldRequired()" [readonly]="fieldReadonly()" class="w-full h-full form-input rounded-r-md" />
        }
      }
      @case ("checkbox") {
        <input [attr.id]="fieldName()" [attr.name]="fieldName()" type="checkbox" [(ngModel)]="fieldModel" [required]="fieldRequired()" [readonly]="fieldReadonly()" class="m-2 w-8 h-8 text-indigo-600 border-gray-300 rounded form-checkbox focus:ring-indigo-600" />
      }
      @case ("textarea") {
        <textarea [attr.id]="fieldName()" [attr.name]="fieldName()" rows="2" [(ngModel)]="fieldModel" [required]="fieldRequired()" [readonly]="fieldReadonly()" class="w-full h-full form-input rounded-r-md" spellcheck="fase"></textarea>
      }
      @default {
        <input [attr.id]="fieldName()" [attr.name]="fieldName()" type="text" [(ngModel)]="fieldModel" [required]="fieldRequired()" [readonly]="fieldReadonly()" class="py-2 w-full h-full form-input rounded-r-md" />
      }
    }
  </gray-label-wrapper>
</div>
