import { Component, Injector, TemplateRef, WritableSignal, effect, inject, input, model, signal } from '@angular/core';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'data-input',
  templateUrl: './data-input.component.html'
})
export class DataInputComponent {
  injector = inject(Injector);
  session = inject(SessionService);

  fieldModel = model.required();

  enableDragDrop = input<boolean>(false);
  ismField = input.required<any>();
  templateRef = input<TemplateRef<any>>();

  fieldLabel: WritableSignal<string> = signal(undefined);
  fieldName: WritableSignal<string> = signal(undefined);
  fieldType: WritableSignal<string> = signal(undefined);
  fieldRequired: WritableSignal<boolean> = signal(false);
  fieldReadonly: WritableSignal<boolean> = signal(false);

  fieldMin: WritableSignal<number | undefined> = signal(undefined);
  fieldMax: WritableSignal<number | undefined> = signal(undefined);

  constructor () {
    effect(() => {
      if (!this.ismField()) {
        return;
      }
      this.fieldName.set(this.ismField().fieldName);
      this.fieldLabel.set(this.ismField().fieldLabel);
      this.fieldType.set(this.ismField().inputType);
      this.fieldRequired.set(this.ismField()?.required || false);
      this.fieldReadonly.set(this.ismField()?.readonly || false);

      let min = this.ismField()?.fieldMin || undefined;
      if (min != null || min === 0) {
        this.fieldMin.set(min);
      }

      let max = this.ismField()?.fieldMax || undefined;
      if (max != null || max === 0) {
        this.fieldMax.set(max);
      }
    }, { allowSignalWrites: true });
  };
};
